<template>
    <span v-html="getContentConvert(params.value) "></span>
</template>

<script>
export default {
    name: "CellRendererContent",
    data(){
        return{
            newsInfo: {}
        }
    },
    computed: {
        getContentConvert() {
            return (value) => {
                return value;
            }
        },
    }
}
</script>

<style scoped>

</style>
